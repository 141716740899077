import React, { useEffect, useRef, useState } from 'react';
import './DeliveryChallanInvoice.css'
import { useReactToPrint } from 'react-to-print';
import { current_user } from '../../../Authentication/AuthenticationContext';

const DeliveryChallanInvoice = ({ isSaleInvoicePrint, onClose }) => {
  const [isCompanydata, setCompanyData] = useState("");
  const [isBranchData, setBranchData] = useState("");
  const [isUserData, setUserData] = useState("");
  const saleInvoice = isSaleInvoicePrint

  const componentRef = useRef();

  const handleDeliveryInvoicePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Sale Invoice',
  });

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await current_user();
      setCompanyData(currentUser.company);
      setBranchData(currentUser.company.branch_info);
      setUserData(currentUser.company.user_info);
    };
    fetchUser();
  }, []);

  const renderHeader = () => (
    <div className="delivery-invoice-header">
      <img src={isCompanydata.logo_url} alt="Company Logo" className="delivery-company-logo" />
      <div className="delivery-company-info">
        <span className='delivery-company-name'>{isCompanydata.name}</span>
        <span>Address: {isBranchData.address}</span>
        <span>Phone: {isUserData.phone}</span>
        <span>Email: {isUserData.email}</span>
        <span>GSTIN : {isCompanydata.gst_number}</span>
      </div>
      <div className="delivery-invoice-info">
        <span className='delivery-invoice-challan-name'>Tax Invoice</span>
        <span>Invoice No: {saleInvoice.bill_number}</span>
        <span>Date: {saleInvoice.date}</span>
        <span>Reference: {saleInvoice.reference_no}</span>
      </div>
    </div>
  );

  const renderTable = () => (
    <table className="delivery-invoice-table">
      <thead>
        <tr className='delivery-tab-head-row'>
          <th className='delivery-table-header-attribute'>SNo</th>
          <th className='delivery-table-header-attribute'>Description</th>
          <th className='delivery-table-header-attribute'>HSN</th>
          <th className='delivery-table-header-attribute'>Qty</th>
          <th className='delivery-table-header-attribute'>Rate</th>
          <th className='delivery-table-header-attribute'>Tax</th>
          <th className='delivery-table-header-attribute'>Amount</th>
        </tr>
      </thead>
      <tbody>
        {saleInvoice.bill_items && saleInvoice.bill_items.map((item, index) => (
          <tr key={index} className='delivery-table-body-row'>
            <td>{index + 1}</td>
            <td className='delivery-table-body-data'>{item.name}</td>
            <td className='delivery-table-body-data'>
              {item.hsn_number}
            </td>
            <td className='delivery-table-body-data'>{item.quantity}</td>
            <td className='delivery-table-body-data'>{item.sales_price.toFixed(2)}</td>
            <td className='delivery-table-body-data'>
              <div className='tax-details-invoice'>
                {item.tax_details.join(", ")} ({item.tax_inclusive ? "Inc" : "Exc"})
              </div>
              {item.item_total_tax.toFixed(2)}
            </td>
            <td className='delivery-table-body-data'>{(item.quantity * item.sales_price).toFixed(2)}</td>
          </tr>
        ))}
        <tr className='delivery-calculation-row'>
          <td colSpan={5} className='delivery-extra-row'>
            <span className='delivery-extra-row-data'>Sub Total</span>
          </td>
          <td></td>
          <td>{saleInvoice.sub_total.toFixed(2)}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr className='delivery-invoice-table-footer-div'>
          <td colSpan={4} rowSpan={5}></td>
          <td colSpan={3} rowSpan={5}>
            <div className="delivery-invoice-footer-data">
              <div className="delivery-invoice-footer-data-row">
                <span className="delivery-invoice-footer-label">Sub Total:</span>
                <span className="delivery-invoice-footer-value">{saleInvoice.sub_total.toFixed(2)}</span>
              </div>
              <div className="delivery-invoice-footer-data-row">
                <span className="delivery-invoice-footer-label">Discount:</span>
                <span className="delivery-invoice-footer-value">{saleInvoice.total_discount || '0.00'}</span>
              </div>
              {saleInvoice.is_gst_enable && saleInvoice.is_igst_enable ? (
                <div className="delivery-invoice-footer-data-row">
                  <span className="delivery-invoice-footer-label">IGST:</span>
                  <span className="delivery-invoice-footer-value">{saleInvoice.total_gst.toFixed(2) || '0.00'}</span>
                </div>
              ) : (
                <>
                  <div className="delivery-invoice-footer-data-row">
                    <span className="delivery-invoice-footer-label">SGST:</span>
                    <span className="delivery-invoice-footer-value">{(saleInvoice.total_gst / 2).toFixed(2) || '0.00'}</span>
                  </div>
                  <div className="delivery-invoice-footer-data-row">
                    <span className="delivery-invoice-footer-label">CGST:</span>
                    <span className="delivery-invoice-footer-value">{(saleInvoice.total_gst / 2).toFixed(2) || '0.00'}</span>
                  </div>
                </>
              )}
              <div className="delivery-invoice-footer-data-row">
                <span className="delivery-invoice-footer-label">TOTAL:</span>
                <span className="delivery-invoice-footer-value">{saleInvoice.total_amount.toFixed(2) || '0.00'}</span>
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  );

  return (
    <div className="delivery-invoice-container" ref={componentRef}>
      <div className='delivery-invoice-button-group'>
        <button className="delivery-invoice-button-print" onClick={handleDeliveryInvoicePrint}>Print</button>
        <button className="delivery-invoice-button-print" onClick={onClose}>Cancel</button>
      </div>
      {renderHeader()}
      <div className='delivery-invoice-horizontal-line'></div>
      <div className='delivery-invoice-billing-address'>
        <div className='delivery-invoice-bill-div'>
          <span>Issued to :</span>
          <span>{saleInvoice?.customer?.customer_name}</span>
          <span>POS : Indore</span>
        </div>
        <div className='delivery-invoice-bill-div'>
          <span>Billing & Shipping Address :</span>
          <span>Demo Address</span>
          <span>Phone: {saleInvoice?.customer?.mobile_number}</span>
          <span>Email: {saleInvoice?.customer?.email}</span>
        </div>
      </div>
      <div className='delivery-invoice-horizontal-line'></div>
      {renderTable()}
      <div className="delivery-invoice-signature-section">
        <span>For {isCompanydata.name}</span>
        <span>Authorized Signature</span>
      </div>
    </div>
  );
};

export default DeliveryChallanInvoice;
