import React, { useState, useCallback, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import debounce from 'lodash/debounce';
import { sendRequest } from "../../ApiRequest";
import { BASE_URL } from "../../Url/constants";
import { getToken } from "../SecureStorage/Token";
import ErrorNotification from "../../Notification/ErrorNotification";
import successNotification from "../../Notification/SuccessNotification";

const PartyBilling = ({ onPartySelect }) => {
  const [selectedParty, setSelectedParty] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showAddPartyModal, setShowAddPartyModal] = useState(false);
  const token = getToken();
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: ''
  });

  const handleSearch = async (query) => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/onboarding/customers/customer_search?query=${query}&type=Party`,
        null,
        token
      );
      setSearchResults(response.data);
    } catch (error) {
      ErrorNotification("Party Not Found");
    }
  };

  const handlePartySelect = (party) => {
    onPartySelect(party);
    setSelectedParty(party.phone);
    setSearchResults([]);
  };

  const handlePartyInputChange = (event) => {
    const { name, value } = event.target;
    let newValue = value;
    let error = '';

    switch (name) {
      case 'first_name':
      case 'last_name':
        newValue = value.replace(/[^A-Za-z]/g, '').slice(0, 32);
        break;
      case 'email':
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (value.length > 64) {
          newValue = value.slice(0, 64);
        }
        if (value && (!emailRegex.test(value) || value.length > 64)) {
          error = "Please enter a valid email address (up to 64 characters)";
        }
        break;
      case 'phone':
        newValue = value.replace(/\D/g, '').slice(0, 10);
        if (newValue.length > 0 && !/^[6-9]\d{9}$/.test(newValue)) {
          error = "Please enter a valid 10-digit phone number";
        }
        break;
      default:
        break;
    }

    setFormData(prev => ({ ...prev, [name]: newValue }));
    setFormErrors(prev => ({ ...prev, [name]: error }));
  };

  const handleAddParty = async (event) => {
    event.preventDefault();
    
    const newErrors = {};
    Object.entries(formData).forEach(([key, value]) => {
      if (value.trim() === '') {
        newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ')} is required`;
      }
    });

    setFormErrors(prevErrors => ({...prevErrors, ...newErrors}));

    if (Object.values({...formErrors, ...newErrors}).some(error => error !== '')) {
      return;
    }

    try {
      const response = await sendRequest(
        "POST",
        `${BASE_URL}/v1/onboarding/customers`,
        { customer: { ...formData, type: 'Party' } },
        token,
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status === 200) {
        successNotification("Party Created Successfully!");
        setShowAddPartyModal(false);
        const newParty = response.data;
        setSearchResults((prevResults) => [newParty, ...prevResults]);
        onPartySelect(newParty);
        setSelectedParty(newParty.phone);
        setFormData({ first_name: '', last_name: '', email: '', phone: '' });
        setFormErrors({});
      }
    } catch (error) {
      console.error("Error:", error);
      ErrorNotification("Failed to create party");
    }
  };


  const handleCloseModal = () => {
    setShowAddPartyModal(false);
    setFormData({ first_name: '', last_name: '', email: '', phone: '' });
    setFormErrors({});
  };

  return (
    <div className="Apps">
      <SearchableDropdownParty
        options={searchResults}
        onSearch={handleSearch}
        label="phone"
        selectedVal={selectedParty}
        handleChange={setSelectedParty}
        onPartySelection={handlePartySelect}
        setSearchResults={setSearchResults}
        onAddPartyClick={() => setShowAddPartyModal(true)}
      />
      <Modal show={showAddPartyModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Party</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddParty}>
            <Form.Group as={Row} controlId="formFirstName">
              <Form.Label column sm={4}>First Name</Form.Label>
              <Col sm={8}>
                <Form.Control 
                  type="text" 
                  name="first_name" 
                  value={formData.first_name}
                  onChange={handlePartyInputChange}
                  isInvalid={!!formErrors.first_name}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.first_name}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formLastName">
              <Form.Label column sm={4}>Last Name</Form.Label>
              <Col sm={8}>
                <Form.Control 
                  type="text" 
                  name="last_name" 
                  value={formData.last_name}
                  onChange={handlePartyInputChange}
                  isInvalid={!!formErrors.last_name}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.last_name}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formEmail">
              <Form.Label column sm={4}>Email</Form.Label>
              <Col sm={8}>
                <Form.Control 
                  type="email" 
                  name="email" 
                  value={formData.email}
                  onChange={handlePartyInputChange}
                  isInvalid={!!formErrors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.email}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formPhone">
              <Form.Label column sm={4}>Phone</Form.Label>
              <Col sm={8}>
                <Form.Control 
                  type="text" 
                  name="phone" 
                  value={formData.phone}
                  onChange={handlePartyInputChange}
                  isInvalid={!!formErrors.phone}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.phone}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <div className="d-flex justify-content-center mt-3">
              <Button variant="secondary" onClick={handleCloseModal}>
                Cancel
              </Button>
              <div style={{ width: '15px' }} />
              <Button variant="primary" type="submit">
                Save
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const SearchableDropdownParty = ({
  options,
  label,
  selectedVal,
  handleChange,
  onSearch,
  onPartySelection,
  setSearchResults,
  onAddPartyClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");
  const inputRef = useRef(null);
  const [errorShown, setErrorShown] = useState(false);

  const debouncedSearch = useCallback(
    debounce((inputValue) => {
      if (inputValue.length >= 2) {
        onSearch(inputValue);
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }, 500),
    []
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const getDisplayValue = () => {
    return query || selectedVal;
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setQuery(inputValue);
    handleChange(inputValue);
    debouncedSearch(inputValue);
  };

  const handleEnter = async (event) => {
    if (event.key === "Enter" && query.trim() !== "") {
      let params = { type: 'Party' };

      if (query.includes("@") && query.includes(".")) {
        params.customer = { email: query };
      } else if (/^[6-9]\d{9}$/.test(query)) {
        params.customer = { phone: query };
      } else {
        if (!errorShown) {
          ErrorNotification("Please enter a valid email address or 10-digit phone number");
          setErrorShown(true);
        }
        return;
      }

      try {
        const response = await sendRequest(
          "POST",
          `${BASE_URL}/v1/onboarding/customers`,
          params,
          getToken(),
          { headers: { "Content-Type": "application/json" } }
        );

        if (response.status === 200) {
          successNotification("Party Created Successfully!");
          setSearchResults((prevResults) => [response.data, ...prevResults]);
          onPartySelection(response.data);
          setQuery(response.data.phone || response.data.email);
          setIsOpen(false);
        }
      } catch (error) {
        console.error("Error:", error);
        ErrorNotification("Failed to create party");
      }
    }
  };

  return (
    <div className="dropdown">
      <div className="col-md-14 input-search-bill">
        <input
          ref={inputRef}
          type="text"
          value={getDisplayValue()}
          name="searchTerm"
          placeholder="Search By Party Email Or Phone"
          className="form-control"
          onChange={handleInputChange}
          onKeyPress={handleEnter}
          onFocus={() => {
            if (query.length >= 2) {
              setIsOpen(true);
            }
          }}
          autoComplete="off"
        />
        <div className="search-icon text-black-50 bg-white ps-1 me-2">
          <FontAwesomeIcon icon={faSearch} />
        </div>
      </div>
      {isOpen && options.length === 0 && (
        <div className="no-results">
          <div>No results found</div>
          <Button variant="link" onClick={onAddPartyClick} className="mt-2">
            Add Party
          </Button>
        </div>
      )}
      {isOpen && options.length > 0 && (
        <div className="options open">
          {options.map((option, index) => (
            <div
              onClick={() => {
                setQuery(option.phone || option.email);
                onPartySelection(option);
                handleChange(option.phone || option.email);
                setIsOpen(false);
              }}
              className={`option ${option[label] === selectedVal ? "selected" : ""}`}
              key={index}
            >
              <div>
                {option.first_name} {option.last_name}
                {option.email && option.phone ? ", " : ""}
                {option.email}
                {option.phone ? ", " : ""}
                {option.phone}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PartyBilling;
