import React, { useEffect, useState } from "react";
import "./item.css";
import { getToken } from "../SecureStorage/Token";
import { BASE_URL } from "../../Url/constants";
import { Link, useNavigate } from "react-router-dom";
import { sendRequest } from "../../ApiRequest";
import ErrorNotification from "../../Notification/ErrorNotification";
import { NotificationContainer } from "react-notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-tooltip/dist/react-tooltip.css";
import {
  faChevronDown,
  faExclamationTriangle,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import successNotification from "../../Notification/SuccessNotification";
import ItemPurchasePrice from "./ItemPopup/ItemPurchasePrice";
import Copyrights from "../Copyrights";
import TransliterateInput from "../TransliterateInput";
import { TransliterationProvider } from "../../TransliterationContext";
import { useTranslation } from "react-i18next";
import { current_user } from "../../Authentication/AuthenticationContext";

const ItemAddPrice = ({ setting }) => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [categoryValue, setCategoryValue] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemCode, setItemCode] = useState("");
  const [barCode, setBarCode] = useState("");
  const [hsn, setHsn] = useState("");
  const [salesPrice, setSalesPrice] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [unit, setUnit] = useState("");
  const [units, setUnits] = useState([]);
  const [wholesalePrice, setWholesalePrice] = useState("");
  const [itemImage, setItemImage] = useState(null);
  const [billImage, setBillImage] = useState(null);
  const [discountType, setDiscountType] = useState("Fixed");
  const [salesDiscount, setSalesDiscount] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [taxType, setTaxType] = useState("Individual");
  const [individualTaxes, setIndividualTaxes] = useState([]);
  const [groupTaxes, setGroupTaxes] = useState([]);
  const [tax, setTax] = useState([]);
  const [taxGroup, setTaxGroup] = useState([]);
  const [purchaseTaxGroup, setPurchaseTaxGroup] = useState([]);
  const [purchaseTax, setPurchaseTax] = useState([]);
  const [isTaxInclusive, setIsTaxInclusive] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [settingConfig, setSettingConfig] = useState("");
  const [purchasePricePopup, setPurchasePricePopup] = useState(false);
  const [discountError, setDiscountError] = useState(false);
  const [purchaseTaxType, setPurchaseTaxType] = useState("individualtax");
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [translatedName, setTranslatedName] = useState({});
  const [transliteratedName, setTransliteratedName] = useState("");
  const [isVisible, setIsVisible] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { t } = useTranslation();
  const [isPharmaceutical, setIsPharmaceutical] = useState(false);
  const [mrp, setMrp] = useState("");
  const [batchNo, setBatchNo] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [mrpError, setMrpError] = useState("");
  const [batchNoError, setBatchNoError] = useState("");
  const [expiryDateError, setExpiryDateError] = useState("");

  useEffect(() => {
    if (Array.isArray(setting)) {
      setSettingConfig(setting);
    } else {
      setSettingConfig([]);
    }
  }, [setting]);

  const token = getToken();

  useEffect(() => {
    fetchCategories();
    fetchUnits();
    handleTaxTypeChange({ target: { value: "Individual" } });
  }, []);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
    }
  }, []);

  useEffect(() => {
    calculateDiscountPrice();
  }, [salesPrice, salesDiscount, discountType]);

  const fetchCategories = async () => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/inventory_managment/categories`,
        null,
        token
      );
      setCategories(response.data.data);
    } catch (error) {
      ErrorNotification("Error fetching categories:", error);
    }
  };

  const fetchUser = async () => {
    try {
      const currentUser = await current_user();
      setIsPharmaceutical(currentUser.company.business_type === 'Pharmaceutical');
    } catch (error) {
      console.error('Error fetching user:', error);
      setIsPharmaceutical(false);
    }
  };

  useEffect(() => {
    const initializeUser = async () => {
      await fetchUser();
    };
        initializeUser();
  }, []);

  const fetchUnits = async () => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/inventory_managment/units`,
        null,
        token
      );
      setUnits(response.data.data);
    } catch (error) {
      ErrorNotification("Error fetching units:", error);
      setUnits([]);
    }
  };

  const calculateDiscountPrice = () => {
    if (salesPrice && salesDiscount && discountType) {
      let calculatedDiscount = 0;
      if (discountType === "Fixed") {
        calculatedDiscount = parseFloat(salesDiscount);
        if (calculatedDiscount > parseFloat(salesPrice)) {
          setDiscountError(true);
          setDiscountPrice(0);
        } else {
          setDiscountError(false);
          setDiscountPrice(parseFloat(salesPrice) - calculatedDiscount);
        }
      } else if (discountType === "Percentage") {
        if (parseFloat(salesDiscount) > 100) {
          setDiscountError(true);
          setDiscountPrice(0);
        } else {
          setDiscountError(false);
          calculatedDiscount =
            (parseFloat(salesPrice) * parseFloat(salesDiscount)) / 100;
          setDiscountPrice(parseFloat(salesPrice) - calculatedDiscount);
        }
      }
    }
  };

  const handleCategoryChange = (event) => {
    setCategoryValue(event.target.value);
  };

  const handleItemNameChange = (event) => {
    setItemName(event.target.value);
  };

  const handleItemNameChangeHindi = async (value) => {
    if (selectedLanguage === "hi") {
      setTranslatedName({ [selectedLanguage]: value });
      setItemName(value);
    }

    try {
      const response = await fetch(
        `https://inputtools.google.com/request?text=${itemName}&itc=hi-t-i0-und&num=4&cp=0&cs=1&ie=utf-8&oe=utf-8&app=demopage`
      );

      const data = await response.json();
      if (data[0] === "SUCCESS") {
        const transliterations = data[1][0][0];
        setTransliteratedName(transliterations || itemName);
      }
    } catch (error) {
      console.error("Error fetching transliterations:", error);
      setTransliteratedName(itemName);
    }
  };
  const handleItemCodeChange = (event) => {
    setItemCode(event.target.value);
  };

  const handleMrpBlur = () => {
    setMrpError("");
  };

  const handleExpiryDateBlur = () => {
    setExpiryDateError("");
  };

  const handleBatchNoBlur = () => {
    setBatchNoError("");
  };

  const handleMrpChange = (event) => {
    const value = event.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;
    
    if (value === "") {
      setMrp(value);
      setMrpError("");
    } else if (regex.test(value) && value.length <= 12) {
      setMrp(value);
      setMrpError("");
    } else {
      setMrpError("MRP should only contain numbers and a max length of 12.");
    }
  };

  const handleBatchNoChange = (event) => {
    const value = event.target.value;
    const regex = /^[a-zA-Z0-9]*$/;

    if (value === "") {
      setBatchNo(value);
      setBatchNoError("");
    } else if (regex.test(value) && value.length <= 40) {
      setBatchNo(value);
      setBatchNoError("");
    } else {
      setBatchNoError("Batch No should be alphanumeric with a max length of 40.");
    }
  };
  
  const handleExpiryDateChange = (event) => {
    const selectedDate = new Date(event.target.value);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    if (event.target.value === "") {
      setExpiryDate(event.target.value);
      setExpiryDateError("");
    } else if (selectedDate >= today) {
      setExpiryDate(event.target.value);
      setExpiryDateError("");
    } else {
      setExpiryDateError("Expiry date must be today or a future date.");
    }
  };

  const handleBarCodeChange = (event) => {
    setBarCode(event.target.value);
  };

  const handleHsnChange = (event) => {
    setHsn(event.target.value);
  };

  const handleSalesPriceChange = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    if (value.length <= 10) {
      setSalesPrice(value);
    }
  };

  const handleQuantityChange = (event) => {
    const value = event.target.value;
    if (value === "-1" || value.replace(/\D/g, "").length <= 10) {
      setQuantity(value);
    }
  };

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };

  const handleWholesalePriceChange = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    if (value.length <= 10) {
      setWholesalePrice(value);
    }
  };

  const handleImageChange = (event) => {
    setItemImage(event.target.files[0]);
  };

  const handlebillImageChange = (event) => {
    setBillImage(event.target.files[0]);
  };

  const handleDiscountTypeChange = (event) => {
    const newDiscountType = event.target.value;
    setDiscountType(newDiscountType);

    if (newDiscountType === "Percentage" && parseFloat(salesDiscount) > 100) {
      setSalesDiscount("");
      setDiscountError(false);
    }

    calculateDiscountPrice();
  };

  const handleSalesDiscount = (event) => {
    let newDiscount = event.target.value;

    newDiscount = newDiscount.replace(/[^0-9.]/g, "");

    if (newDiscount.replace(".", "").length > 10) {
      ErrorNotification("Maximum 10 digits allowed");
      return;
    }
    const parsedDiscount = parseFloat(newDiscount);

    if (discountType === "Percentage" && parsedDiscount > 100) {
      setDiscountError(true);
    } else if (
      discountType === "Fixed" &&
      parsedDiscount > parseFloat(salesPrice)
    ) {
      setDiscountError(true);
    } else {
      setDiscountError(false);
    }
    setSalesDiscount(newDiscount);
  };

  const handleDiscountPrice = (event) => {
    setDiscountPrice(event.target.value);
  };

  const handleTaxTypeChange = async (event) => {
    const selectedTaxType = event.target.value;
    setTaxType(selectedTaxType);
    try {
      if (selectedTaxType === "Individual") {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/taxes/taxes`,
          null,
          token
        );
        setIndividualTaxes(response?.data.data || []);
      } else if (selectedTaxType === "Group") {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/taxes/tax_groups`,
          null,
          token
        );
        setGroupTaxes(response?.data.data || []);
      }
    } catch (error) {
      ErrorNotification("Error fetching taxes:", error);
    }
  };

  const handleTaxchange = (event) => {
    setTax(event.target.value);
  };

  const handletaxGroupchange = (event) => {
    setTaxGroup(event.target.value);
  };

  const handleIsTaxInclusiveChange = (event) => {
    setIsTaxInclusive(event.target.checked);
  };

  const handleVisibleChange = (event) => {
    setIsVisible(!event.target.checked);
  };

  const handleSubmit = async () => {
    setIsButtonDisabled(true);
    setSubmitted(true);
    if (!itemName || !salesPrice || !quantity || !unit || discountError) {
      ErrorNotification(
        discountError
          ? discountType === "Percentage"
            ? "Discount percentage cannot be greater than 100%."
            : "Discount cannot be greater than sales price."
          : "Fields are required."
      );
      return;
    }
    if (!purchasePrice && quantity !== "-1") {
      ErrorNotification("Please add Purchase Price");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("item[category_id]", categoryValue);
      formData.append("item[item_name]", itemName);
      formData.append("item[item_name_en]", transliteratedName);
      if (selectedLanguage === "hi" && translatedName.hi) {
        formData.append(
          "item[translated_name]",
          JSON.stringify(translatedName)
        );
      }

      formData.append("item[item_code]", itemCode);
      formData.append("item[bar_code]", barCode);
      formData.append("item[hsn]", hsn);
      formData.append("item[sales_price]", salesPrice);
      formData.append("item[purchase_price]", purchasePrice);
      formData.append("item[quantity]", quantity);
      formData.append("item[unit_id]", unit);
      formData.append("item[wholesale_price]", wholesalePrice);
      if (itemImage instanceof File) {
        formData.append("item[item_image]", itemImage);
      }
      if (billImage instanceof File) {
        formData.append("item[bill_image]", billImage);
      }
      formData.append("item[discount_type]", discountType);
      formData.append("item[sales_discount]", salesDiscount);
      formData.append("item[discount_price]", discountPrice);
      formData.append("item[tax_type]", taxType);
      formData.append("item[tax_rate_id]", tax);
      formData.append("item[tax_group_id]", taxGroup);
      formData.append("item[purchase_tax_type]", purchaseTaxType);
      formData.append("item[purchase_tax_rate_id]", purchaseTax);
      formData.append("item[purchase_tax_group_id]", purchaseTaxGroup);
      formData.append("item[is_tax_inclusive]", isTaxInclusive);
      formData.append("item[is_visible]", isVisible);
      if (isPharmaceutical) {
        formData.append("item[mrp]", mrp);
        formData.append("item[batch_no]", batchNo);
        formData.append("item[expiry_date]", expiryDate);
      }

      await sendRequest(
        "POST",
        `${BASE_URL}/v1/inventory_managment/items`,
        formData
      );
      successNotification("Item Created!");
      navigate("/inventory");
    } catch (error) {
      error.data.errors.map((error) => ErrorNotification(error));
      setIsButtonDisabled(false);
    }
  };

  const handleCancel = () => {
    navigate("/inventory");
  };

  let isWholesalePriceEnabled = "";
  let isUnitEnabled = "";
  let isCategoryEnabled = "";
  let isPurchaseEnable = "";
  let isItemTaxEnable = "";
  let isItemDiscountEnable = "";
  let barCodeAutoGenerate = "";

  if (Array.isArray(settingConfig)) {
    const wholesaleSetting = settingConfig.find(
      (config) => config.name === "wholesale_price"
    );
    isWholesalePriceEnabled = wholesaleSetting?.item_setting_config?.status;

    const unitSetting = settingConfig.find(
      (config) => config.name === "item_unit"
    );
    isUnitEnabled = unitSetting?.item_setting_config?.status;

    const categorySetting = settingConfig.find(
      (config) => config.name === "item_category"
    );
    isCategoryEnabled = categorySetting?.item_setting_config?.status;

    const purchaseSetting = settingConfig.find(
      (config) => config.name === "party_wise_item_rate"
    );
    isPurchaseEnable = purchaseSetting?.item_setting_config?.status;
    const itemTaxSetting = settingConfig.find(
      (config) => config.name === "item_wise_tax"
    );
    isItemTaxEnable = itemTaxSetting?.item_setting_config?.status;

    const itemDiscountSetting = settingConfig.find(
      (config) => config.name === "item_wise_discount"
    );
    isItemDiscountEnable = itemDiscountSetting?.item_setting_config?.status;

    const itemBarCodeSetting = settingConfig.find(
      (config) => config.name === "bar_code_auto_generate"
    );
    barCodeAutoGenerate = itemBarCodeSetting?.item_setting_config?.status;
  }

  const isSalesPriceLess =
    salesPrice !== "" && parseFloat(salesPrice) < purchasePrice;

  const handleshowPurchase = () => {
    setPurchasePricePopup(true);
  };

  const handleClosepopup = () => {
    setPurchasePricePopup(false);
  };

  // const handlePurchasePriceSave = (data) => {
  //   const { purchasePrice, tax, taxGroup, purchaseTaxType } = data;
  //   setPurchaseTaxGroup(taxGroup);
  //   setPurchaseTax(tax);
  //   setPurchasePrice(purchasePrice);
  //   setPurchaseTaxType(purchaseTaxType);
  // };

  const handlePurchasePriceSave = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    if (value.length <= 10) {
      setPurchasePrice(value);
    }
  };

  return (
    <>
      <TransliterationProvider>
        <Tooltip id="my-tooltip" />
        <div className="item-add-price-content">
          <div className="create-item">
            <div className="row">
              <div className="col-md-4">
                <label>{t("Item Name")}</label>
                {selectedLanguage === "hi" ? (
                  <TransliterateInput
                    type="text"
                    className={`form-control ${
                      submitted && !itemName && "required-field"
                    }`}
                    placeholder={t("Item Name*")}
                    originalValue={itemName}
                    onChange={handleItemNameChangeHindi}
                  />
                ) : (
                  <input
                    type="text"
                    className={`form-control ${
                      submitted && !itemName ? "required-field" : ""
                    }`}
                    placeholder={t("Item Name*")}
                    value={itemName}
                    maxLength={45}
                    onChange={handleItemNameChange}
                  />
                )}
              </div>

              <div className="col-md-4">
                <label>{t("Item Code")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Item Code")}
                  value={itemCode}
                  maxLength={15}
                  onChange={handleItemCodeChange}
                />
              </div>
              <div className="col-md-4">
                <label>{t("Bar Code")}.</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Bar Code")}
                  value={barCode}
                  maxLength={20}
                  onChange={handleBarCodeChange}
                  disabled={barCodeAutoGenerate}
                  {...(barCodeAutoGenerate && {
                    "data-tooltip-id": "my-tooltip",
                    "data-tooltip-content":
                      "Please enable this from item settings",
                  })}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-2 mt-2">
                <label>{t("Hsn No")}.</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("HSN Number")}
                  value={hsn}
                  maxLength={8}
                  onChange={handleHsnChange}
                />
              </div>
              <div className="col-md-2 mt-2">
                <label>{t("Category")}</label>
                <div className="custom-select">
                  <select
                    className="form-control default-selection"
                    value={categoryValue}
                    onChange={handleCategoryChange}
                    disabled={!isCategoryEnabled}
                    {...(!isCategoryEnabled && {
                      "data-tooltip-id": "my-tooltip",
                      "data-tooltip-content":
                        "Please enable this from item settings",
                    })}
                  >
                    <option value="" disabled>
                      {t("Select Category")}
                    </option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                  <div className="select-icon">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <label>{t("Unit")}</label>
                <div className="custom-select">
                  <select
                    // className="form-control default-selection"
                    className={`form-control ${
                      submitted && !itemName && "required-field"
                    }`}
                    value={unit}
                    onChange={handleUnitChange}
                  >
                    <option value="" disabled>
                      {t("Select Unit")}*
                    </option>
                    {units.map((unit) => (
                      <option key={unit.id} value={unit.id}>
                        {unit.unit_name}
                      </option>
                    ))}
                  </select>
                  <div className="select-icon">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                </div>
              </div>
              <div className="col-sm-4 mt-2-item-image">
                <label>{t("Item Image")}</label>
                <input
                  type="file"
                  className="form-control"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </div>
            </div>

            <div className="row mt-2">
              {/* <div className="col-md-3"> */}
                {/* {isPurchaseEnable ? (
                  <Link onClick={() => handleshowPurchase()}>
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="add-purchase-plus-icon"
                    />
                    <span className="add-purchase-label">
                      {t("Add Purchase")}
                    </span>
                  </Link>
                ) : (
                  <p>{t("Please enable purchase price from item settings")}</p>
                )} */}
                <div className="col-md-3">
                  <label>{t("Purchase Price")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Purchase Price")}
                    value={purchasePrice}
                    onChange={handlePurchasePriceSave}
                    // disabled={!isWholesalePriceEnabled}
                    // {...(!isWholesalePriceEnabled && {
                    //   "data-tooltip-id": "my-tooltip",
                    //   "data-tooltip-content":
                    //     "Please enable this from item settings",
                    // })}
                  />
                </div>
              {/* </div> */}
              <div className="col-md-3">
                <label>{t("Wholesale Price")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Wholesale Price")}
                  value={wholesalePrice}
                  onChange={handleWholesalePriceChange}
                  disabled={!isWholesalePriceEnabled}
                  {...(!isWholesalePriceEnabled && {
                    "data-tooltip-id": "my-tooltip",
                    "data-tooltip-content":
                      "Please enable this from item settings",
                  })}
                />
              </div>
              <div className="col-md-3">
                <label>
                  {t("Sales Price")}
                  <span className="tax-per-unit">(per unit)</span>
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    submitted && !salesPrice && "required-field"
                  }`}
                  placeholder={t("Sales Price*")}
                  value={salesPrice}
                  onChange={handleSalesPriceChange}
                />
                {isSalesPriceLess && (
                  <div className="price-warning">
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      className="warning-icon"
                    />
                    {t("Sales price is less than purchase price")}
                  </div>
                )}
              </div>
              <div className="col-md-3">
                <label>{t("Quantity")}</label>
                <input
                  type="text"
                  className={`form-control ${
                    submitted && !quantity && "required-field"
                  }`}
                  placeholder={t("Quantity*")}
                  value={quantity}
                  onChange={handleQuantityChange}
                />
              </div>
            </div>

            {isItemDiscountEnable && (
              <div className="row mt-3 align-items-center">
                <div className="col-md-3">
                  <div className="radio-group d-flex">
                    <label className="item-label">
                      <input
                        type="radio"
                        name="group1"
                        value="Fixed"
                        checked={discountType === "Fixed"}
                        onChange={handleDiscountTypeChange}
                      />
                      <span className="custom-radio"></span>
                      Fixed
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="radio-group d-flex">
                    <label className="item-label ml-3">
                      <input
                        type="radio"
                        name="group1"
                        value="Percentage"
                        checked={discountType === "Percentage"}
                        onChange={handleDiscountTypeChange}
                      />
                      <span className="custom-radio"></span>
                      Percent(%)
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <label>
                    {t("Sales Discount")}
                    <span className="tax-per-unit">({discountType})</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Sales Discount")}
                    value={salesDiscount}
                    onChange={handleSalesDiscount}
                    disabled={!isItemDiscountEnable}
                    {...(!isItemDiscountEnable && {
                      "data-tooltip-id": "my-tooltip",
                      "data-tooltip-content":
                        "Please enable this from item settings",
                    })}
                  />
                  {discountError && (
                    <div className="error-message">
                      {discountType === "Percentage"
                        ? "Discount percentage cannot be greater than 100%."
                        : "Discount cannot be greater than sales price."}
                    </div>
                  )}
                </div>

                <div className="col-md-3">
                  <label>
                    {t("Discounted Price")}
                    <span className="tax-per-unit">(per unit)</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Discount Price")}
                    value={discountPrice}
                    onChange={handleDiscountPrice}
                    disabled={!isItemDiscountEnable}
                    {...(!isItemDiscountEnable && {
                      "data-tooltip-id": "my-tooltip",
                      "data-tooltip-content":
                        "Please enable this from item settings",
                    })}
                  />
                </div>
              </div>
            )}

            <div className="row mt-2">
              <div className="col-md-3">
                <div className="radio-group d-flex">
                  <label className="item-label">
                    <input
                      type="radio"
                      name="taxType"
                      className="tax-radio-color"
                      value="Individual"
                      checked={taxType === "Individual"}
                      onChange={handleTaxTypeChange}
                    />
                    <span className="custom-radio"></span>
                    {t("Individual Tax")}
                  </label>
                </div>
              </div>

              <div className="col-md-3">
                <div className="radio-group d-flex">
                  <label className="item-label ml-3">
                    <input
                      type="radio"
                      name="taxType"
                      value="Group"
                      checked={taxType === "Group"}
                      onChange={handleTaxTypeChange}
                    />
                    <span className="custom-radio"></span>
                    {t("Group Tax")}
                  </label>
                </div>
              </div>
              <div className="col-md-3">
                <label>
                  {t("Taxes")}
                  <span className="tax-per-unit">({taxType})</span>
                </label>
                <div className="custom-select">
                  <select
                    name="tax"
                    className="form-control default-selection"
                    onChange={
                      taxType === "Individual"
                        ? handleTaxchange
                        : handletaxGroupchange
                    }
                    disabled={!isItemTaxEnable}
                    {...(!isItemTaxEnable && {
                      "data-tooltip-id": "my-tooltip",
                      "data-tooltip-content":
                        "Please enable this from item settings",
                    })}
                  >
                    <option value="" selected disabled>
                      {t("Select Tax")}
                    </option>
                    {taxType === "Individual"
                      ? individualTaxes.map((tax) => (
                          <option key={tax.id} value={tax.id}>
                            {tax.title}
                          </option>
                        ))
                      : groupTaxes.map((taxGroup) => (
                          <option key={taxGroup.id} value={taxGroup.id}>
                            {taxGroup.name}
                          </option>
                        ))}
                  </select>
                  <div className="select-icon">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                </div>
              </div>

              <div className="col-md-3 mt-3">
                <div className="tax-item-container">
                  <div>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={isTaxInclusive}
                        onChange={handleIsTaxInclusiveChange}
                        disabled={!isItemTaxEnable}
                        {...(!isItemTaxEnable && {
                          "data-tooltip-id": "my-tooltip",
                          "data-tooltip-content":
                            "Please enable this from item settings",
                        })}
                      />
                      <span className="slider round"></span>
                    </label>
                    <span className="toggle-label">{t("Tax Inclusive")}</span>
                  </div>
                  <div className="">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={!isVisible}
                        onChange={handleVisibleChange}
                      />
                      <span className="slider round"></span>
                    </label>
                    <span className="toggle-label">{t("Not Saleable")}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col-md-3">
                <label className="me-2">{t("Upload Purchase Bill")}</label>
                <input
                  type="file"
                  className="form-control"
                  accept=".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png"
                  onChange={handlebillImageChange}
                />
              </div>

              {isPharmaceutical && (
                <>
                  <div className="col-md-3">
                    <label>{t("MRP")}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("MRP")}
                      value={mrp}
                      maxLength={12}
                      onChange={handleMrpChange}
                      onBlur={handleMrpBlur}
                    />
                    {mrpError && (
                      <span className="text-danger">{mrpError}</span>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label>{t("Batch No")}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("Batch No")}
                      value={batchNo}
                      maxLength={40}
                      onChange={handleBatchNoChange}
                      onBlur={handleBatchNoBlur}
                    />
                    {batchNoError && (
                      <span className="text-danger">{batchNoError}</span>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label>{t("Expiry Date")}</label>
                    <input
                      type="date"
                      className="form-control"
                      value={expiryDate}
                      onChange={handleExpiryDateChange}
                      onBlur={handleExpiryDateBlur}
                    />
                    {expiryDateError && (
                      <span className="text-danger">{expiryDateError}</span>
                    )}
                  </div>
                </>
              )}

              <div className="col-md-12 text-end">
                <button
                  className="btn btn-primary me-2"
                  onClick={handleSubmit}
                  disabled={isButtonDisabled}
                >
                  {t("Save")}
                </button>
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={handleCancel}
                >
                  {t("Cancel")}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* {purchasePricePopup && (
          <div className="purchase-item-popup-overlay">
            <ItemPurchasePrice
              onClose={handleClosepopup}
              onSave={(data) => handlePurchasePriceSave(data)}
            />
          </div>
        )} */}

        <NotificationContainer />
        <Copyrights />
      </TransliterationProvider>
    </>
  );
};

export default ItemAddPrice;
