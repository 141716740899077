import React, { useEffect, useState } from "react";
import { BsFillTrashFill } from "react-icons/bs";
import "./BillingTableOne.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareMinus, faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

function BillingTableOne({
  selectedItems,
  updateActualPrice,
  updateTotalPrice,
  updateTotalTaxPrice,
  updateTotalDiscount,
  updateItems,
  billSettings,
  isPartyEnabled,
  isPharmaceutical,
}) {
  const [items, setItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [actualPrice, setActualPrice] = useState(0);
  const { t } = useTranslation();
  
  useEffect(() => {
    const updatedItems = selectedItems.reduce(
      (acc, item) => {
        const existingItem = acc.find((i) => i.item_code === item.item_code);
        if (!existingItem) {
          const totalStockQuantity = item?.stocks?.reduce(
            (sum, stock) => sum + stock.quantity,
            0
          );
          const taxDetails = [];
          if (item?.tax_type === "Individual" && item?.tax_info) {
            const taxTitle = item.tax_info.title.toLowerCase().includes("gst")
              ? "GST"
              : item.tax_info.title;

            taxDetails.push(
              `${taxTitle}: ${item.tax_info.tax_value}${
                item.tax_info.tax_type === "fixed" ? "F" : "%"
              }`
            );
          } else if (item?.tax_type === "Group" && item?.group_tax_info) {
            item.group_tax_info.forEach((tax) => {
              const taxTitle = tax.title.toLowerCase().includes("gst") ? "GST" : tax.title;

              taxDetails.push(
                `${taxTitle}: ${tax.tax_value}${tax.tax_type === "fixed" ? "F" : "%"}`
              );
            });
          }
          acc.push({
            ...item,
            quantityUpdated: false,
            actualQuantity: totalStockQuantity,
            quantity: 1,
            diffrence_discount: 0,
            stock_ids: [],
            tax_info: (taxDetails.length > 0) ?  taxDetails : [],
            editedWholesalePrice: item.wholesale_price,
            originalSalesPrice: item.sales_price 
          });
        }
        return acc;
      },
      [...items]
    );
    setItems(updatedItems);
  }, [selectedItems, isPartyEnabled]);

  const calculateTaxAmount = (price, taxConfig) => {
    if (typeof taxConfig === 'object' && !Array.isArray(taxConfig)) {
      if (taxConfig.tax_type === 'percentage') {
        return (price * taxConfig.tax_value) / 100;
      } else if (taxConfig.tax_type === 'fixed') {
        return taxConfig.tax_value;
      }
      return 0;
    }

    if (Array.isArray(taxConfig)) {
      return taxConfig.reduce((total, tax) => {
        if (tax.tax_type === 'percentage') {
          return total + (price * tax.tax_value) / 100;
        } else if (tax.tax_type === 'fixed') {
          return total + tax.tax_value;
        }
        return total;
      }, 0);
    }
    
    return 0;
  };

  useEffect(() => {
    const actual_price = items.reduce((acc, item) => {
      return acc + item.quantity * (isPartyEnabled ? item.editedWholesalePrice : item.sales_price);
    }, 0);
  
    const total = items.reduce((acc, item) => {
      const price = isPartyEnabled ? item.editedWholesalePrice : item.sale_amount;
      return acc + item.quantity * price - item.diffrence_discount;
    }, 0);
  
    const total_tax = items.reduce((acc, item) => {
      const taxAmount = isPartyEnabled ? item.wholesale_tax_amount : item.tax_amount;
      return acc + item.quantity * taxAmount;
    }, 0);
  
    const total_discount = items.reduce((acc, item) => {
      const discountAmount = isPartyEnabled ? item.wholesale_discount_amount : item.discount_amount;
      return acc + item.quantity * discountAmount + item.diffrence_discount;
    }, 0);
    setActualPrice(actual_price);
    setTotalPrice(total);
    setTotalTax(total_tax);
    setTotalDiscount(total_discount);
    updateActualPrice(actual_price)
    updateTotalPrice(total);
    updateTotalTaxPrice(total_tax);
    updateTotalDiscount(total_discount);
  }, [items, isPartyEnabled]);

  const isCheckboxEnabled = (optionKey) => {
    const option = billSettings.find((setting) => setting.name === optionKey);
    return option && option.billing_setting_config
      ? option.billing_setting_config.status
      : false;
  };

  const autoDiscountEnabled = isCheckboxEnabled("auto_discount");
  const taxEnabled = isCheckboxEnabled("item_wise_tax");

  const handleQuantityChange = (index, newQuantity) => {
    const updatedItems = [...items];
    const itemActualQuantity = updatedItems[index].actualQuantity;
    const clampedQuantity = Math.max(
      1,
      Math.min(newQuantity, itemActualQuantity)
    );
    const itemPrice = isPartyEnabled
      ? updatedItems[index].editedWholesalePrice
      : updatedItems[index].sales_price;
    const { salesPrice, saleAmount, diffrenceDiscount, stockIds } = calculateSalesData(updatedItems[index], clampedQuantity, itemPrice);
    updatedItems[index].quantity = clampedQuantity;
    updatedItems[index].sales_price = salesPrice;
    updatedItems[index].sale_amount = saleAmount;
    updatedItems[index].diffrence_discount = diffrenceDiscount;
    if (!Array.isArray(updatedItems[index].stock_ids)) {
      updatedItems[index].stock_ids = [];
    }
    updatedItems[index].stock_ids = [...new Set([...updatedItems[index].stock_ids, ...stockIds])];  
    updatedItems[index].quantityUpdated = true;
    setItems(updatedItems);
    updateItems(updatedItems);
  };

  const handleWholesalePriceChange = (index, newPrice) => {
    const updatedItems = [...items];
    const item = updatedItems[index];
    const parsedPrice = newPrice === '' || isNaN(parseFloat(newPrice)) ? 0 : parseFloat(newPrice);
    
    item.editedWholesalePrice = parsedPrice;
    
    if (item.tax_type === 'Group' && Array.isArray(item.group_tax_info)) {
      item.wholesale_tax_amount = calculateTaxAmount(parsedPrice, item.group_tax_info);
    } else if (item.tax_type === 'Individual' && item.tax_info) {
      item.wholesale_tax_amount = calculateTaxAmount(parsedPrice, item.tax_info);
    }
  
    const { salesPrice, saleAmount, diffrenceDiscount, stockIds } = calculateSalesData(
      item, 
      item.quantity, 
      parsedPrice
    );
  
    item.sales_price = salesPrice;
    item.sale_amount = saleAmount;
    item.diffrence_discount = diffrenceDiscount;
    item.stock_ids = [...new Set([...item.stock_ids, ...stockIds])];
    
    setItems(updatedItems);
    updateItems(updatedItems);
  };


  const handleDeleteItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
    updateItems(updatedItems);
  };

  const formatPrice = (price) => {
    return price.toFixed(2);
  };

  function calculateSalesData(item, requestedQty, itemPrice) {
    let remainingQty = requestedQty;
    let totalPrice = 0;
    let diffrenceDiscount = 0;
    let usedStocks = [];
    
    if (Array.isArray(item.stocks) && item.stocks.length > 0) {
      // const sortedStocks = [...item.stocks].sort((a, b) => a.sale_price - b.sale_price);
      const sortedStocks = [...item.stocks].sort((a, b) => {
        if (isPharmaceutical) {
          return new Date(a.expire_date) - new Date(b.expire_date);
        } else {
          return a.sale_price - b.sale_price;
        }
      });  
  
      for (let stock of sortedStocks) {
        if (remainingQty <= 0) break;
        const applicableQty = Math.min(remainingQty, stock.quantity);
        totalPrice += applicableQty * itemPrice;
        usedStocks.push({id: stock.id, price: itemPrice, qty: applicableQty });
  
        remainingQty -= applicableQty;
      }
    } else {
      // If there are no stocks, just use the requested quantity and item price
      totalPrice = requestedQty * itemPrice;
    }
  
    const stockIds = usedStocks.map(stock => stock.id);
    const salesPrice = itemPrice;
    diffrenceDiscount = salesPrice * requestedQty - totalPrice;
    const saleAmount = item.is_tax_inclusive ? (salesPrice - (item.tax_amount + item.discount_amount)) : (salesPrice - item.discount_amount);
    return {
      salesPrice,
      saleAmount,
      diffrenceDiscount,
      stockIds
    };
  }

  const renderItemData = () => {
    return items.map((item, index) => (
      <tr key={index}>
        <td className="centered">{index + 1}</td>
        <td className="centered">{item?.item_code}</td>
        <td className="centered">
          {item?.item_name}
          <div className="tax-font-size">
            {item.tax_info.length > 0 ? item?.tax_info.join(", ") : ''}
          </div>
          {item?.identifiers?.length > 0 && (
            <>
              {item?.identifiers.map((identifier, index) => (
                <span key={index} className="identifier-span">
                  {identifier?.selected_identifier
                    ? `(${identifier?.identifier_type}: ${identifier?.selected_identifier})`
                    : item?.query && identifier.identifier === item?.query && identifier?.item_id === item?.id
                    ? `(${identifier?.identifier_type}: ${identifier?.identifier})`
                    : null}
                </span>
              ))}
            </>
          )}
        </td>

        <td className="centered quantity-container-1">
          <div className="quantity-control">
            <button
              className="quantity-button-1"
              onClick={() => handleQuantityChange(index, item.quantity - 1)}
              disabled={item.quantity <= 1 || (item?.identifiers && item?.identifiers?.length > 0)}
            >
              <FontAwesomeIcon icon={faSquareMinus} />
            </button>
            <span className="quantity-number">{item.quantity}</span>
            <button
              className="quantity-button-1"
              onClick={() => handleQuantityChange(index, item.quantity + 1)}
              disabled={item.quantity >= item.actualQuantity || (item?.identifiers && item?.identifiers?.length > 0)}
            >
              <FontAwesomeIcon icon={faSquarePlus} />
            </button>
          </div>
        </td>
        <td className="centered">
          {isPartyEnabled ? (
            <input
              type="number"
              value={item.editedWholesalePrice}
              onChange={(e) => handleWholesalePriceChange(index, e.target.value)}
              className="wholesale-price-input"
              min="0"
              step="0.01"
              onBlur={(e) => {
                if (e.target.value === '') {
                  handleWholesalePriceChange(index, '0');
                }
              }}
            />
          ) : (
            // When party mode is disabled, always use original sales price
            formatPrice(item.quantity * item.originalSalesPrice)
          )}
          <span className="tax-label">
            <strong style={{ color: item.is_tax_inclusive ? "grey" : "grey", fontSize: '10px' }}>
              ({item.is_tax_inclusive ? "Incl.Tax" : "Excl.Tax"})
            </strong>
          </span>
        </td>
        {autoDiscountEnabled && (
          <td className="centered">
            {formatPrice((item.quantity * (isPartyEnabled ? item.wholesale_discount_amount : item.discount_amount)) + item.diffrence_discount)}
          </td>
        )}
        {taxEnabled && (
          <td className="centered">
            {formatPrice(item.quantity * (isPartyEnabled ? item.wholesale_tax_amount : item.tax_amount))}
          </td>
        )}

        <td className="centered">
          {formatPrice((item.quantity * (isPartyEnabled ? item.editedWholesalePrice : item.sale_amount)) - item.diffrence_discount)}
        </td>
        {/* <td className="centered">
          {formatPrice((item.quantity * item.sale_amount) - item.diffrence_discount)}
        </td> */}
        <td className="centered">
          <a className="deletebtn" onClick={() => handleDeleteItem(index)}>
            <BsFillTrashFill />
          </a>
        </td>
      </tr>
    ));
  };

  return (
    <div className="table-container billing-table-container">
      <table className="table">
        <thead className="sticky-header">
          <tr>
            <th scope="col" className="centered column-width-1">
              #
            </th>
            <th scope="col" className="centered column-width-2">
              {t("Item Code")}
            </th>
            <th scope="col" className="centered column-width-3">
              {t("Item Name")}
            </th>
            <th scope="col" className="centered column-width-4">
              {t("Quantity")}
            </th>
            {isPartyEnabled ? (
            <th scope="col" className="centered column-width-4">
              {t("WholeSale Price")}
            </th>
            ) : (
              <th scope="col" className="centered column-width-4">
              {t("Actual Price")}
            </th>
            )}
            {autoDiscountEnabled && (
              <th scope="col" className="centered column-width-5">
                {t("Discount")}
              </th>
            )}
            {taxEnabled && (
              <th scope="col" className="centered column-width-5">
                {t("Tax")}
              </th>
            )}
            <th scope="col" className="centered column-width-5">
              {t("Taxable Amount")}
            </th>
            <th scope="col" className="centered column-width-6">
              {t("Actions")}
            </th>
          </tr>
        </thead>
        <tbody>{renderItemData()}</tbody>
        {items.length > 0 && (
          <tfoot className="sticky-footer">
            <tr>
              <td colSpan="4" className="centered">
                <strong>{t("Total Price")}</strong>
              </td>
              <td colSpan="1" className="centered">
                <strong>₹{formatPrice(actualPrice)}</strong>
              </td>
              {autoDiscountEnabled && (
                <td colSpan="1" className="centered">
                  <strong>₹{formatPrice(totalDiscount)}</strong>
                </td>
              )}
              {taxEnabled && (
                <td colSpan="1" className="centered">
                  <strong>₹{formatPrice(totalTax)}</strong>
                </td>
              )}
              <td colSpan="1" className="centered">
                <strong>₹{formatPrice(totalPrice)}</strong>
              </td>
              <td colSpan="1"></td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
}

export default BillingTableOne;
